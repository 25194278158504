<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="类型">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择位类型分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAddCourse"
            >添加营销课程</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="marketCourseList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="45"
          align="center"
        ></el-table-column>
        <el-table-column label="类型" prop="type" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type == 1">日语</div>
            <div v-else-if="scope.row.type == 2">韩语</div>
            <div v-else-if="scope.row.type == 3">法语</div>
          </template>
        </el-table-column>
        <el-table-column label="主推/副推" prop="is_main" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.is_main == 1">主推</div>
            <div v-else-if="scope.row.is_main == 0">副推</div>
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="副标题" prop="desc" align="center"></el-table-column>
        <el-table-column label="封面" align="center" width="200">
          <template slot-scope="scope">
            <div @click="openThumb(scope.row.cover)" class="thumb_box" v-if="scope.row.cover">
                <img :src="scope.row.cover" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price" align="center"></el-table-column>
        <el-table-column label="折扣价格" prop="fav_price" align="center"></el-table-column>
        <el-table-column label="活动时长" prop="endTime" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.endTime == 1800">30min</div>
            <div v-else-if="scope.row.endTime == 3600">60min</div>
            <div v-else-if="scope.row.endTime == 10800">180min</div>
            <div v-else-if="scope.row.endTime == ''">无时长</div>
          </template>
        </el-table-column>
        <el-table-column label="是否显示" prop="close" align="center">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.close == 1"
            >
              显示
            </el-tag>
            <el-tag type="danger" v-else
              >隐藏
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="toEditCourse(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeCourse(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 缩略图 -->
    <el-dialog title="缩略图详情" :visible.sync="detailsDialog" :center=true width="40%">
      <div style="width:100%;height:100%">
        <img style="width:100%;height:100%" :src="detailsImg" alt="">
      </div>
    </el-dialog>

    <!-- 添加、编辑营销课程 -->
    <el-dialog :title="isAdd?'添加营销课程':'编辑营销课程'" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="是否主推" label-width="100px">
          <el-select
            v-model="addForm.is_main"
            placeholder="请选择是否主推"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isMain"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="标题" label-width="100px">
            <el-input v-model="addForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="副标题" label-width="100px">
            <el-input v-model="addForm.desc" placeholder="请输入副标题"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="原价" label-width="100px">
            <el-input v-model="addForm.price" placeholder="请输入原价"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="折扣价" label-width="100px">
            <el-input v-model="addForm.fav_price" placeholder="请输入折扣价"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="活动时长" label-width="100px" v-if="addForm.is_main == 1">
          <!-- <el-date-picker
            v-model="addForm.end_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss" 
            placeholder="选择时间"
            >
          </el-date-picker> -->
          <el-select
            v-model="addForm.end_time"
            placeholder="请选择活动时长"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in timeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="分类" label-width="100px">
          <el-select
            v-model="addForm.type"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in typeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="是否展示" label-width="100px">
          <el-select
            v-model="addForm.close"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in closeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="封面图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
              style="width:270px;height:150px"
            >
                <img
                  v-if="thumbSrc"
                  style="width: 100%"
                  :src="thumbSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
            <el-row>
              <div class="tips">上传封面图尺寸为900*500且不大于10MB</div>
            </el-row>
        </el-form-item>
        <el-form-item el-form-item label="导流海报" label-width="100px" v-if="addForm.is_main == 1">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUploadBanner"
              style="width:340px;height:50px"
            >
                <img
                  v-if="bannerSrc"
                  style="width: 100%"
                  :src="bannerSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
            <!-- <el-row>
              <div class="tips">上传封面图尺寸为900*500且不大于10MB</div>
            </el-row> -->
        </el-form-item>
        <el-form-item el-form-item label="详情图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUploadContent"
              style="width:270px;height:500px"
            >
                <img
                  v-if="contentSrc"
                  style="width: 100%"
                  :src="contentSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
        </el-form-item>
        <!-- <el-form-item el-form-item label="详情" label-width="100px">
            <el-card style="height: 680px;">
                <quill-editor v-model="addForm.content" ref="myQuillEditor" style="height: 500px;" :options="editorOption"></quill-editor>
            </el-card>
        </el-form-item> -->
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editNow" >立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
var COS = require("cos-js-sdk-v5");
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  AdvertCosToken
} from "@/api/advertisingList.js";
import {
  marketCourseList,
  marketCourseAdd,
  marketCoursUpdate,
  marketCourseDel
} from "@/api/marketingCourse.js";
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      queryForm: {
        type: 1,
        page: 1,
        limit: 10,
        // start_time: null,
        // end_time: null,
      },
      marketCourseList: [],
      typeArr: [
        { key: 1, value: "日语" },
        { key: 2, value: "韩语" },
        { key: 3, value: "法语" },
      ],
      timeArr: [
        // { key: 176790, value: "测试" },
        { key: 1800, value: "30min" },
        { key: 3600, value: "60min" },
        { key: 10800, value: "180min" },
      ],
      closeArr: [
        { key: 1, value: "显示" },
        { key: 0, value: "隐藏" },
      ],
      isMain: [
        { key: 1, value: "是" },
        { key: 0, value: "否" },
      ],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        type: "",
        title: "",
        cover: null,
        price: "",
        content: null,
        desc: "",
        fav_price: "",
        end_time: null,
        close: null,
        is_main: null,
        advert: null,
      },
      thumbSrc: "",
      contentSrc: "",
      bannerSrc: "",
      detailsImg: "",
      detailsDialog: false,
      isAdd: true,
      editorOption: {}
    };
  },
  created() {
    this.getCourseList();
  },

  methods: {
    openThumb(src) {
      this.detailsDialog = true;
      this.detailsImg = src;
    },
    // 获取商品列表
    getCourseList() {
      marketCourseList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.marketCourseList = res.data.data;
      });
    },

    // 点击添加商品按钮
    toAddCourse() {
      this.isAdd = true;
      this.addDialog = true;
    },

    // 上传封面图
    beforeThumbUpload(file) {
      let that = this;
      const is1M = file.size / 1024 / 1024 < 10;
      const isSize = new Promise(function (resolve, reject) {
        let width = 900; 
        let height = 500;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          AdvertCosToken({ suffix: file.name }).then((res) => {
            var cos = new COS({
              getAuthorization: function (options, callback) {
                callback({
                  TmpSecretId: res.data.credentials.tmpSecretId,
                  TmpSecretKey: res.data.credentials.tmpSecretKey,
                  XCosSecurityToken: res.data.credentials.sessionToken,
                  StartTime: res.data.startTime,
                  ExpiredTime: res.data.expiredTime,
                  expiration: res.data.expiration,
                  requestId: res.data.requestId,
                });
              },
            });
            cos.putObject(
              {
                Bucket: res.data.Bucket,
                Region: res.data.Region,
                Key: res.data.key,
                // ContentType:'multipart/form-data',
                Body: file, // 上传文件对象
                onProgress: function (progressData) {},
              },
              function (err, data) {
                if (data) {
                  that.addForm.cover = res.data.key;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                    that.$forceUpdate();
                  };
                }
              }
            );
          });
          return file;
        },
        () => {
          that.$message.error(
            "上传封面图尺寸限制为900 x 500，大小不可超过10MB"
          );
          // return Promise.reject();
        }
      );
    },

    // 上传导流海报图
    beforeThumbUploadBanner(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      AdvertCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.advert = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.bannerSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 上传详情图
    beforeThumbUploadContent(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      AdvertCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.content = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.contentSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 添加任务
    addNow() {
      if (this.addForm.is_main == null) {
        this.$message.error("请选择是否主推");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入副标题");
        return;
      } else if (this.addForm.price == "") {
        this.$message.error("请输入价格");
        return;
      } else if (this.addForm.fav_price == "") {
        this.$message.error("请输入折扣价");
        return;
      } else if (this.addForm.end_time == "" && this.addForm.is_main == 1) {
        this.$message.error("请选择活动时长");
        return;
      } else if (this.addForm.type == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.addForm.close == null) {
        this.$message.error("请选择是否展示");
        return;
      }  else if (this.addForm.cover == null) {
        this.$message.error("请上传封面图");
        return;
      } else if (this.addForm.advert == null && this.addForm.is_main == 1) {
        this.$message.error("请上传导流海报图");
        return;
      } else if (this.addForm.content == null) {
        this.$message.error("请上传详情内容");
        return;
      }
      marketCourseAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加营销课程成功");
        this.addDialog = false;
        this.getCourseList();
      });
    },
    // 点击编辑按钮
    toEditCourse(row) {
      this.isAdd = false;
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(row));
      this.thumbSrc = this.addForm.cover;
      this.contentSrc = this.addForm.content;
      this.bannerSrc = this.addForm.advert;
      this.$set(this.addForm,'end_time',row.endTime)
    },

    // 点击立即修改按钮
    editNow() {
      if (this.addForm.is_main == null) {
        this.$message.error("请选择是否主推");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入副标题");
        return;
      } else if (this.addForm.price == "") {
        this.$message.error("请输入价格");
        return;
      } else if (this.addForm.fav_price == "") {
        this.$message.error("请输入折扣价");
        return;
      } else if (this.addForm.end_time == "" && this.addForm.is_main == 1) {
        this.$message.error("请选择活动时长");
        return;
      } else if (this.addForm.type == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.addForm.close == null) {
        this.$message.error("请选择是否展示");
        return;
      }  else if (this.addForm.cover == null) {
        this.$message.error("请上传封面图");
        return;
      } else if (this.addForm.advert == null && this.addForm.is_main == 1) {
        this.$message.error("请上传导流海报图");
        return;
      } else if (this.addForm.content == null) {
        this.$message.error("请上传详情内容");
        return;
      }
      if(this.addForm.cover.indexOf(".com/") != -1){
        this.addForm.cover = this.addForm.cover.split("?")[0].split(".com/")[1]
      }
      if(this.addForm.content.indexOf(".com/") != -1){
        this.addForm.content = this.addForm.content.split("?")[0].split(".com/")[1]
      }
      // if(this.addForm.advert.indexOf(".com/") != -1){
      //   this.addForm.advert = this.addForm.advert.split("?")[0].split(".com/")[1]
      // }
      marketCoursUpdate(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑营销课程成功");
        this.addDialog = false;
        this.getCourseList();
      });
    },
    // 删除课程
    async removeCourse(id) {
      const result = await this.$confirm("是否要删除该课程?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      marketCourseDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除课程成功");
        this.getCourseList();
      });
    },
    // 弹窗关闭事件
    closed() {
      this.addForm = {
        type: "",
        title: "",
        cover: "",
        price: "",
        content: "",
        desc: "",
        fav_price: "",
        end_time: "",
        close: "",
      };
      this.thumbSrc = "";
      this.contentSrc = "";
      this.bannerSrc = "";
    },

    //  查询
    toQuery() {
      if (this.queryForm.display === "") {
        this.queryForm.display = null;
      }
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getCourseList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getCourseList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getCourseList();
    },
  },
};
</script>

<style lang="less" scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 180px;
    height: 100px;
  }
}

.tips {
  color: #666666;
}

::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
