import request from "@/utils/request";

// 广告列表
export function AdvertList(params) {
  return request({
    url: "/admin/base/advertList",
    method: "get",
    params
  });
}

// 获取显示位置分类
export function AdvertPositionArr(params) {
  return request({
    url: "/admin/base/advertPositionArr",
    method: "get",
    params
  });
}

// 获取类型分类
export function AdvertTypeArr(params) {
  return request({
    url: "/admin/base/advertTypeArr",
    method: "get",
    params
  });
}

// 广告显示或不显示
export function AdvertDisplay(data) {
  return request({
    url: "/admin/base/advertDisplay",
    method: "put",
    data
  });
}

// 删除广告
export function AdvertDel(data) {
  return request({
    url: "/admin/base/advertDel",
    method: "delete",
    data
  });
}

// 添加广告
export function AdvertAdd(data) {
  return request({
    url: "/admin/base/advertAdd",
    method: "post",
    data
  });
}

// 编辑广告
export function AdvertEdit(data) {
  return request({
    url: "/admin/base/advertEdit",
    method: "put",
    data
  });
}

// 广告上传COS签名
export function AdvertCosToken(params) {
  return request({
    url: "/admin/base/advertCosToken",
    method: "get",
    params
  });
}


