import request from "@/utils/request";

// 获营销课程列表
export function marketCourseList(data) {
  return request({
      url: "/admin/base/indexCourseList",
      method: "get",
      params: data
  });
}
// 添加营销课程
export function marketCourseAdd(data) {
  return request({
      url: "/admin/base/indexCourseAdd",
      method: "post",
      data
  });
}
// 编辑营销课程
export function marketCoursUpdate(data) {
  return request({
      url: "/admin/base/indexCourseUpdate",
      method: "post",
      data
  });
}
// 删除营销课程
export function marketCourseDel(data) {
  return request({
      url: "/admin/base/indexCourseDel",
      method: "delete",
      data
  });
}